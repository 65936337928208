document.addEventListener('alpine:init', () => {
    Alpine.data('slider', (count, type) => ({

        count: count,
        slideCount: count,
        activeIndex: 0,
        type: type,
        
        prev() {
            this.adjustCount();
            if (this.activeIndex > 0) {
                this.$refs.slideContainer.scrollLeft = this.$refs.slideContainer.clientWidth * (this.activeIndex - 1);
                this.activeIndex--;
            }
        },

        next() {
            this.adjustCount();
            if (this.activeIndex < this.slideCount - 1) {
                this.$refs.slideContainer.scrollLeft = this.$refs.slideContainer.clientWidth * (this.activeIndex + 1);
                this.activeIndex++;
            }
        },

        adjustCount() {
            if (this.type === 'products-slider') {
                if (window.innerWidth < 640) {
                    this.slideCount = Math.ceil(this.count / 2)
                }
                if (window.innerWidth >= 640) {
                    this.slideCount = Math.ceil(this.count / 3)
                }
                if (window.innerWidth >= 1024) {
                    this.slideCount = Math.ceil(this.count / 4)
                }
                if (window.innerWidth >= 1220) {
                    this.slideCount = Math.ceil(this.count / 5)
                }
            }
            if (this.type === 'landingpage-slider') {
                if (window.innerWidth < 640) {
                    this.slideCount = Math.ceil(this.count / 2)
                }
                if (window.innerWidth >= 640) {
                    this.slideCount = Math.ceil(this.count / 3)
                }
                if (window.innerWidth >= 1024) {
                    this.slideCount = Math.ceil(this.count / 4)
                }
            }
            if (this.type === 'bubbles-banner') {
                if (window.innerWidth < 330) {
                    this.slideCount = Math.ceil(this.count / 2)
                }
                if (window.innerWidth >= 420) {
                    this.slideCount = Math.ceil(this.count / 3)
                }
                if (window.innerWidth >= 520) {
                    this.slideCount = Math.ceil(this.count / 4)
                }
                if (window.innerWidth >= 620) {
                    this.slideCount = Math.ceil(this.count / 5)
                }
                if (window.innerWidth >= 720) {
                    this.slideCount = Math.ceil(this.count / 6)
                }
                if (window.innerWidth >= 820) {
                    this.slideCount = Math.ceil(this.count / 7)
                }
                if (window.innerWidth >= 920) {
                    this.slideCount = Math.ceil(this.count / 8)
                }
                if (window.innerWidth >= 1020) {
                    this.slideCount = Math.ceil(this.count / 9)
                }
                if (window.innerWidth >= 1024) {
                    this.slideCount = Math.ceil(this.count / 7)
                }
                if (window.innerWidth >= 1144) {
                    this.slideCount = Math.ceil(this.count / 8)
                }
            }
        },

        resetSlider() {
            this.activeIndex = 0;
            this.$refs.slideContainer.scrollLeft = 0;
        }

    }));
});