document.addEventListener('alpine:init', () => {
    Alpine.store('wishlist', {

        numberOfItems: null,
        productIds: [],

        setNumberOfItems(numberOfItems) {
            this.numberOfItems = numberOfItems;
        },

        addProductIdsOnWishlist(productIds) {
            productIds.forEach(productId => this.setIsOnWishlist(productId, true));
        },

        isOnWishlist(productId) {
            return this.productIds.indexOf(productId) > -1;
        },

        setIsOnWishlist(productId, isOn) {
            if(isOn && !this.isOnWishlist(productId)) {
                this.productIds.push(productId);
            }
            if(!isOn && this.isOnWishlist(productId)) {
                this.productIds.splice(this.productIds.indexOf(productId), 1);
            }
        },

        async toggle(productId) {
            if(this.isOnWishlist(productId)) {
                this.removeProduct(productId);
                return false;
            } else {
                this.addProduct(productId);
                return true;
            }
        },

        async addProduct(productId) {

            const url = '/ws/wishlist/add'
                +'/sku/'+productId
                +'/form_key/'+window.mageFormKey;

            return fetch(url, {
                credentials: 'include'
            }).then(response => {

                if(!response.ok) {
                    throw new Error('response status '+response.status+'/'+response.statusText);
                }
                return response.json();

            }).then(data => {

                if(data.status === 'ok') {
                    this.setIsOnWishlist(productId, true);
                    this.setNumberOfItems(data.numberOfItems);
                    
                    // tracking
                    window.algolia.add_to_wishlist(productId);
                    window._uxa.push(['trackPageEvent', 'Wishlist Product Added']);
                    (window.rtbhEvents || []).push({ eventType: 'wishlist', offerId: productId }, { eventType: 'uid', id: window.rtb_house_uid });
                }

            }).catch((error) => {

                console.log(error);
            })
        },

        async removeProduct(productId) {

            const url = '/ws/wishlist/remove'
                +'/sku/'+productId
                +'/form_key/'+window.mageFormKey;

            return fetch(url, {
                credentials: 'include'
            }).then(response => {

                if(!response.ok) {
                    throw new Error('response status '+response.status+'/'+response.statusText);
                }
                return response.json();

            }).then(data => {

                if(data.status === 'ok') {
                    this.setIsOnWishlist(productId, false);
                    this.setNumberOfItems(data.numberOfItems);
                }

            }).catch((error) => {

                console.log(error);
            })
        }
    });

    Alpine.data('addToWishlist', (productId) => ({
        isOn: false,
        init() {
            Alpine.effect(() => {
                this.isOn = Alpine.store('wishlist').isOnWishlist(productId);
            });
        },
        toggle() {
            Alpine.store('wishlist')
                .toggle(productId)
                .then(wasAdded => {
                    if(wasAdded) {
                        this.$dispatch('wishlist-item-added', { productId: productId });
                    }
                });
        }
    }));
});