var event;
if(document.createEvent) {
    event = document.createEvent('CustomEvent');
    event.initEvent('DeferredJavascriptLoaded', false, false);
} else {
    event = new CustomEvent('DeferredJavascriptLoaded');
}
window.dispatchEvent(event);

window.defer = function(callback) {
    callback();
};