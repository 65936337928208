// called to add stuff to the cart

function juwelo_render_cart_overlay(data) {
    
    var messages_container = jQuery('#ajax_cart_messages');
    var dialog = jQuery('#addedtocart');
    var relatedContainer = dialog.find('.addedtocart-related-items');
    var relatedList = dialog.find('.related-items');
    var relatedTemplate = dialog.find('.related-items-item:eq(0)');

    // default state

    dialog.hide();
    messages_container.hide();
    relatedContainer.hide();
    relatedTemplate.hide();

    relatedList.find('li').each(function(i,e) {
        if(i > 0) {
            jQuery(e).remove();
        }
    });

    // show error message

    if(data.error) {
        if(messages_container.length > 0) {
            jQuery('#ajax_cart_message_target').text(data.error);
            messages_container.show();
        }
        return;
    }

    // render new product number in header

    jQuery('.trigger-link-cart .cart-amount').text(data.cart.numberOfItems);

    // render confirmation dialog

    _global_console.log(data);

    dialog.find('.added-item-image').attr('src', data.product.image);
    dialog.find('.added-item-name').text(data.product.name);

    if(data.product.oldPriceHtml) {
        dialog.find('.added-item-price').find('.regular-price-box').hide();
        dialog.find('.added-item-price').find('.old-price-box .price').html(data.product.oldPriceHtml);
        dialog.find('.added-item-price').find('.new-price-box .price').html(data.product.priceHtml);
        dialog.find('.added-item-price').find('.old-price-box .alternative-price').html(jQuery('.alternative-price.old-price').html());
        dialog.find('.added-item-price').find('.new-price-box .alternative-price').html(jQuery('.new-price-box').find('.alternative-price.price').html());
    } else {
        dialog.find('.added-item-price').find('.new-price-box,.old-price-box').hide();
        dialog.find('.added-item-price').find('.regular-price-box .price').html(data.product.priceHtml);
        dialog.find('.added-item-price').find('.regular-price-box .alternative-price').html(jQuery('.price-box').find('.alternative-price.price').html());
    }

    if(data.product.variant !== '') {
        dialog.find('.added-item-variant').text(data.product.variant);
        dialog.find('.added-item-variant').show();
    } else {
        dialog.find('.added-item-variant').hide();
    }

    // related items

    if(data.product.related.length > 0) {
        relatedContainer.show();

        for(var i=0,l=data.product.related.length;i<l;i++) {
            var rel = data.product.related[i];
            var li = relatedTemplate.clone();

            (function() {

                li.attr('data-cs-override-id', rel.csIndex);
                li.find('.product-link').attr('href', rel.link);
                li.find('.product-name').text(rel.name);
                li.find('.add-to-wishlist').attr('x-data', rel.addToWishlistData);

                if(rel.oldPriceHtml) {
                    li.find('.price-box').find('.regular-price-box').hide();
                    li.find('.price-box').find('.old-price-box .price').html(rel.oldPriceHtml);
                    li.find('.price-box').find('.new-price-box .price').html(rel.priceHtml);
                } else {
                    li.find('.price-box').find('.new-price-box,.old-price-box').hide();
                    li.find('.price-box').find('.regular-price-box .price').html(rel.priceHtml);
                }

                li.find('.product-image').attr('src', rel.image);
                relatedList.append(li);
                li.show();

                ga_product_impression(rel.trackingDataGa4);
                li.find('.product-link').click(function() {
                    ga_product_click(rel.trackingDataGa4);
                });

            })(rel, li);
        }
    }

    // update cart amount
    var single = data.cart.numberOfItems === 1;
    dialog.find('.cartamount').text(data.cart.numberOfItems);
    if(single) {
        dialog.find('.multiple').hide();
        dialog.find('.single').show();
    } else {
        dialog.find('.single').hide();
        dialog.find('.multiple').show();
    }
    dialog.show();

    dialog[0].dispatchEvent(new CustomEvent('CartDialogOpen'));
}

function juwelo_add_to_ajax_cart(url, jQuery, success) {
    
    jQuery.getJSON(url, function(data) {

        juwelo_render_cart_overlay(data);

        // fire success callback
        
        if(success) {
            success();
        }        
    });
}